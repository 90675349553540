import _ from 'lodash'

import { USER_ROLE_TYPE } from './Constant'
import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetTypeResponse,
  V1RidaApiGetUserGroupResponse,
  V1RidaApiGetUserMeResponse,
  V1RidaApiGetUserOnlineResponse,
  V1RidaApiGetUserResponse,
  V1RidaApiGetUserSearchResponse,
  V1RidaApiPostMerchantUserResponse,
  V1RidaApiPostUserResponse,
  V1RidaApiUser,
} from './Type'

class User {
  public static async getUserMe() {
    const response = await RidaApiService.get<V1RidaApiGetUserMeResponse>(
      'v1/user/me',
    )

    return response.data.data
  }

  public static async getAllUsers(filterOptions?: {
    search?: string
    itemsPerPage?: number
    pageNumber?: number
    groupIds?: string[]
  }) {
    const response = await RidaApiService.get<V1RidaApiGetUserResponse>(
      'v1/user',
      {
        search: filterOptions?.search,
        items_per_page: filterOptions?.itemsPerPage,
        page_number: filterOptions?.pageNumber,
        group_id: filterOptions?.groupIds,
      },
    )

    return response.data.data
  }

  public static async importUsers(
    userData: {
      email: string
      password: string
      phoneNumber: string
      firstName: string
      lastName: string
      preferredName: string
      userVehicleTypeId: string
      userVehicleRegistrationNumber: string
      bankId: string | null
      bankAccountNumber: string | null
      roleTypeId: string | null
      groupCodes: string[]
    }[],
  ) {
    await RidaApiService.post('v1/userBulk', {
      users: _.map(userData, user => {
        return {
          email: user.email,
          password: user.password,
          first_name: user.firstName,
          last_name: user.lastName,
          preferred_name: user.preferredName,
          phone_number: user.phoneNumber,
          user_vehicles: [
            {
              vehicle_type_id: user.userVehicleTypeId,
              vehicle_registration_number: user.userVehicleRegistrationNumber,
            },
          ],
          user_bank_accounts:
            user.bankId && user.bankAccountNumber
              ? [
                  {
                    bank_id: user.bankId,
                    bank_account_number: user.bankAccountNumber,
                  },
                ]
              : [],
          user_roles: user.roleTypeId
            ? [{ user_role_type_id: user.roleTypeId }]
            : [],
          user_groups: _.map(user.groupCodes, c => {
            return {
              group_code: c,
            }
          }),
        }
      }),
    })
  }

  public static async createSingleUser(userData: {
    email: string
    password: string
    phoneNumber: string
    firstName: string
    lastName: string
    preferredName: string
    userVehicleTypeId: number
    userVehicleRegistrationNumber: string
    bankId: number | null
    bankAccountNumber: string | null
    roleTypeId: number[]
    groupIds: string[]
    isAutoAssignEnabled: boolean
    config: {
      equipment: {
        hasThermalBag: boolean
        hasTrolley: boolean
        hasBeenTrainedByOps: boolean
      }
      preference: {
        preferredDeliveryZones: string[]
      }
    }
  }) {
    const response = await RidaApiService.post<V1RidaApiPostUserResponse>(
      'v1/user',
      {
        email: userData.email,
        password: userData.password,
        first_name: userData.firstName,
        last_name: userData.lastName,
        preferred_name: userData.preferredName,
        phone_number: userData.phoneNumber,
        is_auto_assign_enabled: userData.isAutoAssignEnabled,
        user_vehicles: [
          {
            vehicle_type_id: userData.userVehicleTypeId,
            vehicle_registration_number: userData.userVehicleRegistrationNumber,
          },
        ],
        user_bank_accounts:
          userData.bankId && userData.bankAccountNumber
            ? [
                {
                  bank_id: userData.bankId,
                  bank_account_number: userData.bankAccountNumber,
                },
              ]
            : [],
        user_roles: _.map(userData.roleTypeId, g => {
          return {
            user_role_type_id: g,
          }
        }),
        user_groups: _.map(userData.groupIds, g => {
          return {
            group_id: g,
          }
        }),
        config: {
          equipment: {
            has_thermal_bag: userData.config.equipment.hasThermalBag,
            has_trolley: userData.config.equipment.hasTrolley,
            has_been_trained_by_ops:
              userData.config.equipment.hasBeenTrainedByOps,
          },
          preference: {
            preferred_delivery_zones:
              userData.config.preference.preferredDeliveryZones,
          },
        },
      },
    )

    return response.data.data
  }

  public static async getAllTypes() {
    const response = await RidaApiService.get<V1RidaApiGetTypeResponse>(
      'v1/type',
    )

    return response.data.data
  }

  public static async getAllOnlineUsers(filterOptions?: {
    search?: string
    itemsPerPage?: number
    pageNumber?: number
  }) {
    const response = await RidaApiService.get<V1RidaApiGetUserOnlineResponse>(
      'v1/user/online',
      {
        search: filterOptions?.search,
        items_per_page: filterOptions?.itemsPerPage,
        page_number: filterOptions?.pageNumber,
      },
    )

    return response.data.data
  }

  public static async updateUserStatus(
    userId: string,
    userStatusTypeId: number,
  ) {
    await RidaApiService.put(`v1/user/${userId}/status`, {
      user_status_type_id: userStatusTypeId,
    })
  }

  public static async updateSingleUserDetails(
    userId: string,
    details: {
      firstName?: string | null
      lastName?: string | null
      preferredName?: string | null
      email?: string
      phoneNumber?: string | null
      userRoleTypeIds?: number[] | null
      vehicleTypeId?: number | null
      vehicleRegistrationNumber?: string | null
      bankId?: number | null
      bankAccountNumber?: string | null
      groupIds?: string[]
      isAutoAssignEnabled?: boolean
      avatarUrl?: null | string
      config?: {
        equipment: {
          hasThermalBag: boolean
          hasTrolley: boolean
          hasBeenTrainedByOps: boolean
        }
        preference: {
          preferredDeliveryZones: string[]
        }
      }
    },
  ) {
    await RidaApiService.put(`v1/user/${userId}/detail`, {
      first_name: details.firstName,
      last_name: details.lastName,
      preferred_name: details.preferredName,
      email: details.email,
      phone_number: details.phoneNumber,
      is_auto_assign_enabled: details.isAutoAssignEnabled,
      user_roles: details.userRoleTypeIds
        ? _.map(details.userRoleTypeIds, t => {
            return {
              user_role_type_id: t,
            }
          })
        : [],
      user_vehicles:
        details.vehicleTypeId && details.vehicleRegistrationNumber
          ? [
              {
                vehicle_type_id: details.vehicleTypeId,
                vehicle_registration_number: details.vehicleRegistrationNumber,
              },
            ]
          : [],
      user_bank_accounts:
        details.bankId && details.bankAccountNumber
          ? [
              {
                bank_id: details.bankId,
                bank_account_number: details.bankAccountNumber,
              },
            ]
          : [],
      user_groups: _.map(details.groupIds, g => {
        return {
          group_id: g,
        }
      }),
      avatar_url: details.avatarUrl,
      config: details.config
        ? {
            equipment: {
              has_thermal_bag: details.config.equipment.hasThermalBag,
              has_trolley: details.config.equipment.hasTrolley,
              has_been_trained_by_ops:
                details.config.equipment.hasBeenTrainedByOps,
            },
            preference: {
              preferred_delivery_zones:
                details.config.preference.preferredDeliveryZones,
            },
          }
        : undefined,
    })
  }

  public static async searchUser(filterOptions?: {
    search?: string
    itemsPerPage?: number
    pageNumber?: number
    userRoleTypeIds?: number[]
    suggestionJobIds?: string[]
  }) {
    const response = await RidaApiService.get<V1RidaApiGetUserSearchResponse>(
      'v1/user/search',
      {
        search: filterOptions?.search,
        items_per_page: filterOptions?.itemsPerPage,
        page_number: filterOptions?.pageNumber,
        user_role_type_id: filterOptions?.userRoleTypeIds,
        suggestion_for_job_id: filterOptions?.suggestionJobIds,
      },
    )

    return response.data.data
  }

  public static async getAllUserGroups() {
    const response = await RidaApiService.get<V1RidaApiGetUserGroupResponse>(
      'v1/user/group',
    )

    return response.data.data
  }

  public static async registerForMerchant(userData: {
    email: string
    password: string
    phoneNumber: string
    companyName: string
    isCreatedUserOnRidaPlatformRequired: boolean
  }) {
    const response =
      await RidaApiService.post<V1RidaApiPostMerchantUserResponse>(
        'v1/merchant/user',
        {
          email: userData.email,
          password: userData.password,
          phone_number: userData.phoneNumber,
          company_name: userData.companyName,
          is_created_user_on_rida_platform_required:
            userData.isCreatedUserOnRidaPlatformRequired,
        },
      )

    return response.data.data
  }

  public static hasSpecificRolesForUser(user: V1RidaApiUser) {
    const hasSassFleetOperatorRole = _.some(
      user.user_roles,
      r => r.user_role_type_id === USER_ROLE_TYPE.SASS_FLEET_OPERATOR_OWNER,
    )
    const hasSelfManagedRole = _.some(
      user.user_roles,
      r => r.user_role_type_id === USER_ROLE_TYPE.SELF_MANAGED_OWNER,
    )

    return {
      hasSassFleetOperatorRole,
      hasSelfManagedRole,
    }
  }
}

export { User as UserService }
